.baseStyle .main {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: $colorWhite;
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  .no-word-break {
    white-space: nowrap;
  }

  p {
    color: $colorMedGray;
    size: $fontSizeBase;
  }

  pre {
    max-width: 100%;
    word-break: break-all;
    white-space: pre-wrap;

    code {
      height: 100%;
    }
  }

  span.jumbo {
    font-family: Roboto;
    font-size: 5rem;
    font-weight: 100;
  }

  input[type="button"], input[type="submit"], input[type="reset"],
  button[type="button"], button[type="submit"], button[type="reset"],  {
    padding: 10px 20px;
  }

  button.large {
    width: 100%;
  }

  button.small {
    display: inline-flex;
    padding: 5px 10px;
    font-size: $fontSizeSmallest;
    word-break: keep-all;
    background: $colorWhite;
    justify-content: center;
    align-items: center;
    border-radius: $styleBorderRadius;
    border: 1px solid $colorLightGray;
    color: $colorBossanova;
  }

  button.small.dark {
    background: $stylePrimary;
    color: $colorWhite;
    border: 1px solid $colorOffWhite;
  }

  .clockDisplay {
    font-size: $fontSizeJumbo;
    font-weight: 100;
  }
}

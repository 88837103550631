.alert {
  animation: alert 0.8s;
  animation-iteration-count: 10;
}

// thanks https://www.w3schools.com/howto/howto_css_shake_image.asp
@keyframes alert {
  0% {
    background-color: white;
    transform: translate(1px, 1px);
  }
  10% {
    transform:  rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform:  rotate(0deg);
  }
  40% {
    transform: translate(2px, -1px);
  }
  50% {
    background-color: #f00;
    transform:  rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px);
  }
  70% {
    transform: rotate(-1deg);
  }
  80% {
    transform: translate(5px, -1px) rotate(1deg);
  }
  90% {
    transform: rotate(0deg);
  }
  100% {
    background-color: currentColor;
    transform: translate(0, 0) rotate(-1deg);
  }
}

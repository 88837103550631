.debug {
  border: 2px solid red;

  .debug {
    border-color: green;

    .debug {
      border-color: yellow;

      .debug {
        border-color: orange;
      }
    }
  }
}

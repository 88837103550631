.baseStyle, .modal-dialog {
  .main > .panel {
    padding-top: 0;
    margin-top: 0;
  }

  .panel {
    position: relative;
    background-color: $colorWhite;
    border-radius: $styleBorderRadius;
    padding: 20px;
    box-shadow: $styleBoxShadow;
    box-sizing: border-box;
    margin: 10px 0;

    .dragHandle {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    textarea {
      width: 98%;
      min-height: 100px;
      height: auto;
    }
  }

  .panel.short {
    padding: 5px 10px;
    width: auto;
  }

  .panel.white {
    background-color: $colorWhite;
    color: $gray-900;
  }

  .panel.transparent {
    background-color: transparent;
    box-shadow: none;
  }

  .panel.sidebar {
    padding: $stylePaddingSmall/4;

    .row, .column {
      margin: 5px 0;
    }
  }

  #sidebar .panel.sidebar {
    background-image: $stylePrimary;
    color: $white;

    .container-fluid {
      margin: $styleMarginVerticalDefault/2;
    }

    .col,
    .row {
      margin: 0;
    }
  }
}


#sidebar {
  flex: 2;
  position: relative;
  z-index: 6;
  flex-flow: column nowrap;
  box-shadow: $styleBoxShadow;
  background-color: $colorWhite;

  button {
    background-color: $colorWhite;
    border-radius: $border-radius;
    border: none;
  }

  h1, h2, h3, h4 {
    margin: 20px 0;
  }

  h2 {
    text-transform: uppercase;
    color: $colorLightGray;
    font-size: $fontSizeLarge;
  }

  #logo {
    margin: 40px auto;
    max-width: 240px;

    svg {
      max-width: 100%;
    }
  }

  #intro {
    font-size: $fontSizeIntro;
    margin: 40px 0;
    color: $colorDarkGray;
    font-weight: 200;
    line-height: 150%;
  }

  nav {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: -20px;

    div {
      margin: 0;
      padding: 0;
    }

    svg {
      padding-right: 10px;
    }

    a {
      display: flex;
      text-decoration: none;
      color: $colorBossanova;
      font-weight: 300;
      align-items: center;
      padding: 10px 0 10px 30px;
      flex: 1;
      font-size: $fontSizeBase;
    }

    a:hover {
      color: $colorBossanova;
      border-radius: 0 4px 4px 0;
      background-color: $colorLightGray;
    }

    a.active {
      background-image: $stylePrimary;
      border-radius: 0 4px 4px 0;
      color: $colorWhite;

      svg {
        fill: $colorWhite;
      }
    }
  }

  .toolsHistory {
    display: inline-flex;
    flex: 0 0 auto;
    flex-flow: wrap row;

    .sidebarRecent {
      flex: 0 0 auto;
      padding: 5px;
      background-color: $colorBlush;
      border-radius: $styleBorderRadius;
      text-decoration: none;
      color: $colorWhite;
      box-shadow: $styleBoxShadow;
      border: 0;
      margin: $styleMarginVerticalDefault/4;
      font-size: 0.8rem;
    }
  }
}

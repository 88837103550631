.layoutDashboard {
  #appTopNav {
    border-bottom: 10px solid $colorBlush;

    .dashboard {
      height: 100%;
      padding: 2px;
    }

    h3 {
      font-size: 0.8rem;
    }
  }

  .sectionList .dashboard::before {
    content: " ";
    margin: 2px;
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    bottom: 5px;
    right: 5px;
    box-shadow: $styleBoxShadow;
    border-top: 1px solid $colorGigas;
  }

  #appSubNav {
    .row {
      max-height: 100%;
    }
  }

  #appSubNav .dashboard {
    img.img-thumbnail {
      padding: 0;
      margin: 0;
      width: auto;
      max-height: 100%;
    }

    p {
      margin: 0;
    }

    padding: 0;
  }

  .news {
    max-height: 240px;
    overflow-y: scroll;
  }

  .rangeslider.rangeslider-horizontal {
    background-color: $colorWhite;

    .rangeslider__fill {
      background-color: $colorGigas;
    }
  }

  .audioPlayer .backgroundNoiseControls button {
    color: $colorWhite;
  }

  .dashboard {
    span.small {
      font-size: 0.8rem;
    }

    table {
      height: 100%;
      width: 100%;
    }

    margin-left: -15px;
    margin-right: -15px;
    margin-top: 0;

    &.A {
      color: $colorDarkGray;
      border: 0;

      &.sideBorders {
        border-left: 1px solid $colorLightGray;
        border-right: 1px solid $colorLightGray;
      }

      .rangeslider.rangeslider-horizontal {
        background-color: $colorBlush;

        .rangeslider__fill {
          background-color: $colorGigas;
        }
      }

      .audioPlayer .backgroundNoiseControls button {
        color: $colorDarkGray;
      }
    }

    &.B {
      color: $colorWhite;
      background-color: $colorBlush;
      border: 0;

      &.sideBorders {
        border-left: 1px solid $colorLightGray;
        border-right: 1px solid $colorLightGray;
      }

      .rangeslider.rangeslider-horizontal {
        background-color: $colorBlush;

        .rangeslider__fill {
          background-color: $colorGigas;
        }
      }

      .audioPlayer .backgroundNoiseControls button {
        color: $colorDarkGray;
      }
    }
  }

  .sectionList .dashboard {
    height: 100%;
  }
}
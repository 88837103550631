// Colors
$colorGigas: #3D3E9D;
$colorSeance: #72299F;
$colorCuriousBlue: #237EE4;
$colorBlush: #BB426D;
$colorBossanova: #462D64;
$colorWhite: #fff;
$colorOffWhite: #f2f2f2;
$colorBlack: $colorBossanova;
$colorLightGray: #b2b2b2;
$colorMedGray: #555;
$colorDarkGray: #333;

// Styles
$stylePrimary: linear-gradient(to right, $colorGigas, $colorSeance);
$styleBoxShadow: 0 0 5px rgba(72,72,72, 0.3);
$styleBorderRadius: 4px;
$stylePaddingSmall: 5px;
$stylePaddingMedium: 10px;
$stylePaddingLarge: 20px;
$stylePaddingLargest: 40px;
$stylePaddingSmallInline: 0 10px;
$stylePaddingMediumInline: 0 20px;
$styleMarginVerticalDefault: 20px;
$styleMarginHorizontalDefault: 10px;
$styleDefaultBorder: 1px solid $colorOffWhite;

// Buttons
$buttonPrimary: $colorBlush;
$buttonSecondary: $colorCuriousBlue;
$buttontertiary: $colorBossanova;

$fontSizeSmallest: .7rem;
$fontSizeSmaller: .8rem;
$fontSizeSmall: .9rem;
$fontSizeBase: 1rem;
$fontSizeMedium: 1.05rem;
$fontSizeLarge: 1.07rem;
$fontSizeLarger: 1.075rem;
$fontSizeLargest: 1.5rem;
$fontSizePageHeader: 1.09rem;
$fontSizeJumbo: 3rem;
$fontSizeIntro: 1.2rem;


$sideBarWidth: 240px;

.main {
  margin-left: $sideBarWidth;
}

.baseStyle, .modal-dialog {
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-flow: row nowrap;
  font-size: 1rem;

  #mainBackgroundSplash {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 240px;
    background-image: $stylePrimary;
    z-index: 0;
  }

  #layoutSidebarMain {
    display: flex;
    flex-flow: row nowrap;
    min-height: 100vh;
    width: 100%;
    max-width: 100%;
  }

  .main, .modal-body, .defaultModal {
    flex: 10;
    display: flex;
    overflow: hidden;
    position: relative;
    flex-flow: column nowrap;
    background-color: $colorOffWhite;
    font-size: $fontSizeBase;
    margin-bottom: 100px;
    justify-content: flex-start;
    z-index: 1;
    flex-flow: column nowrap;

    h1, h2, h3, h4, h5, h6 {
      margin: 20px 0;
      Columnfont-weight: 500;
    }

    h1 {
      font-size: $fontSizeLargest;
    }

    h2 {
      font-size: $fontSizeLarger;
    }

    h3 {
      font-size: $fontSizeLarge;
    }

    h4 {
      font-size: $fontSizeMedium;
    }

    h5 {
      font-size: $fontSizeBase;
    }

    blockquote {
      border: 1px solid $colorLightGray;
      border-left: none;
      border-right: none;
      padding: 20px;
      background-color: $colorOffWhite;
      font-weight: 500;
    }

    code {
      background-color: #462D64;
      color: #fff;
      padding: 4px;
      font-family: monospace;
      border-radius: 5px;
    }

    del, s {
      color: $colorBlush;
    }

    iframe {
      border: $styleDefaultBorder;
    }

    fieldset {
      border: $styleDefaultBorder;
      margin: $styleMarginVerticalDefault*2;
      padding: $stylePaddingSmall;
    }

    legend, label {
      padding: $stylePaddingMediumInline;
      padding-left: 0;
      font-weight: 500;
    }

    input, textarea, select {
      border: 1px solid $colorLightGray;
      padding: $stylePaddingSmall;
      font-size: $fontSizeBase;
      border-radius: $styleBorderRadius;
      background-color: $colorOffWhite;
      color: $colorBossanova;
    }

    select {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      padding: $stylePaddingSmall ($stylePaddingSmall * 2) $stylePaddingSmall $stylePaddingSmall;
      background-image: url('../../assets/icons/expand_more.svg');
      background-position: right 0px top 50%;
      background-repeat: no-repeat;
      background-size: contain;
    }

    button[type="submit"], button[type="button"], button[disabled], button[type="reset"], input[type="submit"], input[type="button"], input[disabled], input[type="reset"] {
      width: auto;
      padding: $stylePaddingSmallInline;
      color: $colorWhite;
      border-radius: $styleBorderRadius;
      box-shadow: $styleBoxShadow;
      font-size: $fontSizeBase;
      margin: 10px;
      border: 1px solid scale-color($colorWhite, $alpha: -50%);
    }

    button[type="submit"], input[type="submit"] {
      background-color: $colorCuriousBlue;
    }

    button[type="button"], input[type="button"] {
      background-color: $colorSeance;
    }

    button[type="reset"], input[type="reset"] {
      background-color: $colorBlush;
    }

    button[disabled], input[disabled] {
      background-color: $colorLightGray;
    }

    button.small {
      color: $colorBossanova;
      background-color: $colorWhite;
    }
  }

  #sidebar,
  main {
    padding: 0 20px;
  }

  #sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: $sideBarWidth;
  }

  .card-view {
    display: inline-block;
    position: relative;
    background-color: $colorWhite;
    border-radius: $styleBorderRadius;
    padding: 20px;
    box-shadow: $styleBoxShadow;
    border: 1px solid $colorWhite;
    box-sizing: border-box;
    margin: 10px;
    width: auto;
  }

  .card-view.primary {
    color: $colorWhite;
    background-color: $buttonPrimary;
  }

  .card-view.secondary {
    color: $colorWhite;
    background-color: $buttonSecondary;
  }

  .card-view.tertiary {
    color: $colorWhite;
    background-color: $buttontertiary;
  }

  .dashboard.draggable {
    position: relative;
    background-color: $colorWhite;
    border-radius: $styleBorderRadius;
    padding: 20px;
    box-shadow: $styleBoxShadow;
    box-sizing: border-box;
    margin: 10px 0;

    .dragHandle {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    textarea {
      width: 98%;
      min-height: 100px;
      height: auto;
    }
  }

  .gapBottom {
    @include media-breakpoint-up(xs) {
      margin-bottom: 10px;
    }
    @include media-breakpoint-up(sm) {
      margin-bottom: 40px;
    }
  }
}


.isDone {
  @extend .alert;
}


#toolImageShare {
  img {
    max-width: 100%;
  }

  .dropzone {
    border: 1px dashed $colorLightGray;
    padding: 20px;
  }
}

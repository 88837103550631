#topNav {
  position: relative;
  z-index: 3;
  align-items: center;
  margin-bottom: $stylePaddingMedium;

  & .title {
    height: 20px;
    margin-bottom: 14px;

    h1 {
      position: relative;
      z-index: 9;
      font-weight: 300;
      font-size: $fontSizePageHeader*2;
      color: $colorWhite;
      margin-bottom: 10px;
    }
  }

  .pageNav {
    display: flex;
    height: 40px;
    flex: 0 0 auto;
    justify-content: flex-end;
    overflow: hidden;
    margin: 0 10px;

    input.search {
      border: 0;
      border-radius: $styleBorderRadius;
    }

    .pageNavItems {
      color: $colorWhite;
      line-height: 40px;

      p {
        color: $colorWhite;
      }

      .pageNavItem {
        flex-flow: nowrap;
        white-space: nowrap;
        margin-right: 10px;

        image, svg {
          max-height: 100%;
        }
      }

      button {
        font-size: $fontSizeLargest;
        padding: $stylePaddingSmall;
        line-height: 14px;
        margin: 0;
        background-color: $white;
        color: $colorBossanova;
        fill: $colorBossanova;
      }
    }
  }
}
